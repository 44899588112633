import { URLS } from 'constants/api'
import { QUERY_KEYS } from 'constants/queryKeys'
import { addDays, addHours, format, parse } from 'date-fns'
import { paths } from 'driverama-core/api/driverama/generated/appointments'
import { components } from 'driverama-core/api/driverama/generated/opportunities'
import { useLovMakesQuery } from 'driverama-core/api/driverama/lov/lovMakes'
import { useModelList } from 'driverama-core/api/driverama/lov/lovModelsSearch'
import { Flex } from 'driverama-core/components/Flex'
import { TextBody } from 'driverama-core/components/text/Text'
import { HTTPMethod } from 'driverama-core/constants/rest'
import { unique } from 'driverama-core/utils/array'
import { isNotNil } from 'driverama-core/utils/types'
import IconMoney from 'images/IconMoney.svg'
import { Event } from 'react-big-calendar'
import { useTranslation } from 'react-i18next'
import { useQuery, UseQueryOptions } from 'react-query'
import { apiAuthFetcher } from 'utils/authFetcher'
import { DateWithSlotsProps } from '../../../components/calendar/slot/Slot.utils'
import { CalendarFilters } from '../../../sections/wizard/WizardReducer'
import { DailyReportKey } from '../appointments/report'
import {
  CustomerSearchResponse,
  getCustomer,
  useCustomersSearchQuery
} from '../customer/search'
import { useEmployeesSearchQuery } from '../employees/search'
import { useOpportunitiesSellingSearchQuery } from '../opportunities/selling/search'
import { getOrder, OrderSearch, useOrdersSearchQuery } from '../orders/search'
import { getOpportunity, useOpportunitiesSearchQuery } from './opportunity'

type SlotsSearchResponse = paths['/admin/appointments/slots/for-branch/{branchId}/search']['post']['responses']['200']['content']['application/com.driverama-v1+json']

type QueryBody = paths['/admin/appointments/slots/for-branch/{branchId}/search']['post']['requestBody']['content']['application/json']
type QueryOpts = UseQueryOptions<unknown, unknown, SlotsSearchResponse>

type OpportunitySearch = components['schemas']['AdminOpportunitySearchResponse']

type ExtendedInternalTypes = 'DISABLED_HOME_DELIVERY' | 'DISABLED_BUYOUT'

type AppointmentType = SlotsSearchResponse[number]['slots'][number]['appointments'][number]['appointmentType']

const OPPORTUNITIES_APPOINTMENT_TYPES: AppointmentType[] = ['BUYOUT']
const ORDERS_APPOINTMENT_TYPES: AppointmentType[] = [
  'CAR_DELIVERY',
  'CAR_DELIVERY_DEALER'
  // 'CAR_DELIVERY_OTHER',
  // 'CAR_DELIVERY_RETURN',
]

const RECLAMATION_APPOINTMENT_TYPES: AppointmentType[] = [
  'CAR_DELIVERY_RECLAMATION'
]

export interface ParsedEventFromResponse {
  start: Date
  end: Date
  type: AppointmentType
  id: string
}

export type EventState = OpportunitySearch['state'] | OrderSearch['state']

export interface CustomRBEvent extends Event {
  id: string
  type: AppointmentType | ExtendedInternalTypes
  erpId?: string
  state?: EventState
  appointment?: OpportunitySearch['appointment']
  orderAppointment?: OrderSearch['deliveryAppointment']
  lossReason?: OpportunitySearch['lossReason']
  source?: OpportunitySearch['source']
  b2bPartnerCalled?: OpportunitySearch['b2bPartnerCalled']
  responsibleEmployee?: string
  car?: {
    licensePlate?: OpportunitySearch['cars'][number]['licensePlate']
    makeName?: string
    modelName?: string
    made?: number
    vin?: string
    stockNo?: string
  }
}

export function useBookingSlotsQuery(
  branchId: string,
  body: QueryBody,
  opts?: QueryOpts
) {
  return useQuery(
    QUERY_KEYS.slotsSearch(
      branchId,
      body.filter.dateFrom,
      body.filter.dateTo,
      body.filter.types,
      body.filter.employeeIds,
      body.filter.opportunityStates
    ),
    async () => fetchBookingSlots(branchId, body),
    opts
  )
}

export async function fetchBookingSlots(branchId: string, body: QueryBody) {
  const res = await apiAuthFetcher<SlotsSearchResponse>(
    URLS.slotsSearch(branchId),
    {
      method: HTTPMethod.POST,
      body: {
        filter: body.filter
      }
    }
  )
  return res.json
}

export function getBookingSlotsFilters(filters: CalendarFilters[]) {
  return [
    ...(filters.includes('buying') ? OPPORTUNITIES_APPOINTMENT_TYPES : []),
    ...(filters.includes('handover') ? ORDERS_APPOINTMENT_TYPES : []),
    ...(filters.includes('reclamation') ? RECLAMATION_APPOINTMENT_TYPES : [])
  ].filter(isNotNil)
}

function getIdFromAppointment(
  appointment: SlotsSearchResponse[number]['slots'][number]['appointments'][number]
) {
  switch (appointment.appointmentType) {
    case 'BUYOUT':
      return appointment.metadata.opportunityId
    case 'CAR_DELIVERY':
    case 'CAR_DELIVERY_RECLAMATION':
    case 'CAR_DELIVERY_DEALER':
    case 'CAR_DELIVERY_RETURN':
    case 'CAR_DELIVERY_OTHER':
      return appointment.metadata.orderId
    default:
      return null
  }
}

function parseEvents(events?: SlotsSearchResponse) {
  return (events ?? []).reduce((acc: ParsedEventFromResponse[], day) => {
    const parsedDate = parse(day.date, 'yyyy-MM-dd', new Date())

    day.slots.forEach(slot => {
      const start = parse(
        (slot.from as unknown) as string, // TODO: Fix types from API
        'HH:mm:ss',
        parsedDate
      )
      const end = parse((slot.to as unknown) as string, 'HH:mm:ss', parsedDate)
      if (slot.appointments) {
        slot.appointments.forEach(appointment => {
          const id = getIdFromAppointment(appointment)
          if (id) {
            acc.push({
              id,
              type: appointment.appointmentType,
              start,
              end
            })
          }
        })
      }
    })
    return acc
  }, [])
}

function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
  return value !== null && value !== undefined
}

export function isOpportunity(type: unknown) {
  return OPPORTUNITIES_APPOINTMENT_TYPES.includes(type as AppointmentType)
}

export function isOrder(type: unknown) {
  return ORDERS_APPOINTMENT_TYPES.includes(type as AppointmentType)
}

export function isReclamation(type: unknown) {
  return RECLAMATION_APPOINTMENT_TYPES.includes(type as AppointmentType)
}

export function isDisabledHomeDelivery(type: unknown) {
  return type === ('DISABLED_HOME_DELIVERY' as ExtendedInternalTypes)
}

export function isDisabledBuyout(type: unknown) {
  return type === ('DISABLED_BUYOUT' as ExtendedInternalTypes)
}

function parseTitle(customer?: CustomerSearchResponse['content'][number]) {
  switch (customer?.type) {
    case 'COMPANY':
    case 'SELF_EMPLOYED':
      return customer.company?.name

    case 'PERSON':
      return [customer.person?.firstName, customer.person?.surname]
        .filter(isNotNil)
        .join(' ')

    default:
      return undefined
  }
}

export function useEvents(
  events?: SlotsSearchResponse
): {
  isLoading: boolean
  isError?: boolean
  data: CustomRBEvent[]
} {
  const parsedEvents = parseEvents(events)

  const opportunitiesIds = parsedEvents.filter(appointment =>
    OPPORTUNITIES_APPOINTMENT_TYPES.includes(appointment.type)
  )
  const ordersIds = parsedEvents.filter(appointment =>
    [...ORDERS_APPOINTMENT_TYPES, ...RECLAMATION_APPOINTMENT_TYPES].includes(
      appointment.type
    )
  )

  const opportunitiesQuery = useOpportunitiesSearchQuery(
    {
      ids: opportunitiesIds.map(event => event.id)
    },
    {
      enabled: opportunitiesIds.length > 0,
      cacheTime: 0 // TODO: APPS-208
    }
  )

  const ordersQuery = useOrdersSearchQuery(
    {
      ids: ordersIds.map(event => event.id)
    },
    {
      enabled: ordersIds.length > 0,
      cacheTime: 0 // TODO: APPS-208
    }
  )

  const opportunitiesSellingSearchQuery = useOpportunitiesSellingSearchQuery(
    {
      sellingOpportunityIds:
        ordersQuery.data?.content
          .map(order => order.sellingOpportunityId)
          .filter(isNotNil) ?? []
    },
    {
      enabled: !!ordersQuery.data?.content.length,
      cacheTime: 0 // TODO: APPS-208
    }
  )

  const makesAndModels = [
    ...(opportunitiesQuery.data?.content.map(x => x.cars[0]).filter(isNotNil) ??
      []),
    ...(ordersQuery.data?.content
      .map(order => order.cars[0])
      .filter(isNotNil) ?? [])
  ]

  const modelsQuery = useModelList(
    {
      ids: makesAndModels.map(x => x.modelId).filter(isNotNil),
      carMakeIds: makesAndModels.map(x => x.makeId).filter(isNotNil)
    },
    !!makesAndModels.length
  )

  const makeDetailsQuery = useLovMakesQuery(
    {
      active: true
    },
    {
      enabled: !!makesAndModels.length
    }
  )

  const customerIds = [
    ...(opportunitiesQuery.data?.content
      .map(x => x.customerId)
      .filter(notEmpty) ?? []),
    ...(ordersQuery.data?.content.map(order => order.userId).filter(notEmpty) ??
      [])
  ]

  const customersQuery = useCustomersSearchQuery(
    {
      ids: customerIds
    },
    {
      enabled: customerIds.length > 0
    }
  )

  const deliveryEmployeesIds =
    ordersQuery.data?.content
      .map(order => order.deliveryAppointment?.responsibleEmployeeId)
      .filter(notEmpty) ?? []

  const reclamationEmployeesIds =
    ordersQuery.data?.content
      .map(order => order.reclamationAppointment?.responsibleEmployeeId)
      .filter(notEmpty) ?? []

  const employeesIds = [...deliveryEmployeesIds, ...reclamationEmployeesIds]

  const employeesQuery = useEmployeesSearchQuery(
    {
      ids: employeesIds
    },
    {
      enabled: employeesIds.length > 0
    }
  )

  const queries = [
    opportunitiesQuery,
    ordersQuery,
    modelsQuery,
    makeDetailsQuery,
    customersQuery,
    employeesQuery,
    opportunitiesSellingSearchQuery
  ]
  const isLoading = queries.some(query => query.isLoading)
  const isError = queries.some(query => query.isError)

  if (isError) {
    return {
      isLoading,
      isError,
      data: []
    }
  }

  const isAllFetched = !isLoading && !isError

  if (isAllFetched) {
    return {
      isLoading,
      data: parsedEvents.map(event => {
        if (isOpportunity(event.type) && opportunitiesQuery.data) {
          const opportunity = getOpportunity(
            opportunitiesQuery.data?.content,
            event.id
          )

          if (opportunity) {
            const car = opportunity.cars[0]

            const modelName = modelsQuery.models.find(
              x => x.id === car?.modelId
            )?.name

            const makeName = makeDetailsQuery.data?.content.find(
              x => x.id === car?.makeId
            )?.name

            const customer =
              opportunity.customerId && customersQuery.data
                ? getCustomer(
                    customersQuery.data.content,
                    opportunity.customerId
                  )
                : undefined

            return {
              ...event,
              state: opportunity.state,
              source: opportunity.source,
              b2bPartnerCalled:
                opportunity.source === 'B2B'
                  ? opportunity.b2bPartnerCalled
                  : undefined,
              erpId: opportunity.erpId,
              car: {
                licensePlate: car?.licensePlate,
                modelName,
                makeName,
                made: car?.made
              },
              appointment: opportunity.appointment,
              lossReason: opportunity.lossReason,
              title: parseTitle(customer)
            }
          }
        }

        if (
          (isOrder(event.type) || isReclamation(event.type)) &&
          ordersQuery.data
        ) {
          const order = getOrder(ordersQuery.data, event.id)

          if (order) {
            const car = order.cars[0]

            const modelName = modelsQuery.models.find(
              x => x.id === car?.modelId
            )?.name

            const makeName = makeDetailsQuery.data?.content.find(
              x => x.id === car?.makeId
            )?.name

            const customer =
              order.userId && customersQuery.data
                ? getCustomer(customersQuery.data.content, order.userId)
                : undefined

            const responsibleEmployeeId = isReclamation(event.type)
              ? order.reclamationAppointment?.responsibleEmployeeId
              : order.deliveryAppointment?.responsibleEmployeeId

            const employee =
              responsibleEmployeeId && employeesQuery.data
                ? employeesQuery.data.content.find(
                    employee => employee.id === responsibleEmployeeId
                  )
                : undefined

            const erpId = opportunitiesSellingSearchQuery.data?.content.find(
              oppSelling => oppSelling.id === order.sellingOpportunityId
            )?.erpId

            return {
              ...event,
              state: order.state,
              orderAppointment: isReclamation(event.type)
                ? order.reclamationAppointment
                : order.deliveryAppointment,
              erpId,
              responsibleEmployee: [employee?.firstName, employee?.surname]
                .filter(isNotNil)
                .join(' '),
              car: {
                modelName,
                makeName,
                vin: car.vin,
                stockNo: car.stockNo
              },
              title: parseTitle(customer)
            }
          }
        }

        return event
      })
    }
  }

  return {
    isLoading,
    isError,
    data: []
  }
}

export function getSlots(data: SlotsSearchResponse): DateWithSlotsProps[] {
  return data
    .map(x => ({
      date: x.date,
      slots: x.slots.map(slot => ({
        from: (slot.from as unknown) as string,
        to: (slot.to as unknown) as string,
        state: slot.state,
        bookingUnavailabilities: slot.bookingUnavailabilities
      }))
    }))
    .sort((a, b) => {
      const aDate = parse(a.date, 'yyyy-MM-dd', new Date())
      const bDate = parse(b.date, 'yyyy-MM-dd', new Date())

      return aDate.getTime() - bDate.getTime()
    })
}

function isOpportunityShown(state: EventState) {
  return state !== 'DISCARDED'
}

export function isOpportunityEditable(state?: EventState) {
  return state === 'APP_READY' || state === 'LOST'
}

export function isEventEditable(
  type: AppointmentType | ExtendedInternalTypes,
  state?: EventState
) {
  return (
    (isOpportunity(type) && isOpportunityEditable(state)) ||
    isOrder(type) ||
    isReclamation(type)
  )
}

interface DisabledEvents {
  from: string
  to: string
  id: string
}

export function getUniqueDisabledEvents(
  data: DateWithSlotsProps[],
  type: 'CAR_DELIVERY' | 'BUYOUT'
) {
  const uniqueHomeDeliveriesIds = unique(
    data.reduce((acc: string[], cur) => {
      cur.slots.forEach(slot => {
        const unavailabilitiesIds =
          slot.bookingUnavailabilities
            ?.filter(unavailability => unavailability.appointmentType === type)
            .map(unavailability => unavailability.id) ?? []
        acc.push(...unavailabilitiesIds)
      })
      return acc
    }, [])
  )

  return uniqueHomeDeliveriesIds.reduce((acc: DisabledEvents[], cur) => {
    const firstUnavailability = data.find(day =>
      day.slots.find(slot =>
        slot.bookingUnavailabilities?.find(
          unavailability => unavailability.id === cur
        )
      )
    )
    const lastUnavailability = data
      .slice()
      .reverse()
      .find(day =>
        day.slots.find(slot =>
          slot.bookingUnavailabilities?.find(
            unavailability => unavailability.id === cur
          )
        )
      )

    if (firstUnavailability) {
      acc.push({
        from: firstUnavailability.date,
        to: lastUnavailability?.date ?? firstUnavailability.date,
        id: cur
      })
    }
    return acc
  }, [])
}

// functions that catches out overlapping disabled delivery intervals, so they are not displayed in calendar twice
// see https://driverama.atlassian.net/browse/APPS-221
// function removeDuplicatedHomeDeliveries(
//   disabledDeliveries: DisabledHomeDelivery[]
// ) {
//   const longestDisabledInterval = disabledDeliveries
//     .map(item => ({
//       ...item,
//       days: differenceInDays(new Date(item.to), new Date(item.from))
//     }))
//     .sort((a, b) => b.days - a.days)[0]

//   const newDisabledDeliveries = disabledDeliveries.reduce(
//     (acc: DisabledHomeDelivery[], curr) => {
//       if (
//         curr.id !== longestDisabledInterval.id &&
//         areIntervalsOverlapping(
//           {
//             start: new Date(curr.from),
//             end: new Date(curr.to)
//           },
//           {
//             start: new Date(longestDisabledInterval.from),
//             end: new Date(longestDisabledInterval.to)
//           },
//           { inclusive: true }
//         )
//       ) {
//         return acc
//       }

//       return [...acc, curr]
//     },
//     []
//   )

//   return newDisabledDeliveries
// }

function getSortingTypeNumber(type: CustomRBEvent['type']) {
  if (isOpportunity(type)) {
    return 1
  }
  if (isOrder(type)) {
    return 2
  }
  if (isReclamation(type)) {
    return 3
  }
  if (isDisabledHomeDelivery(type)) {
    return 4
  }

  return 0
}

export function useSlots(data?: SlotsSearchResponse) {
  const { isLoading, isError, data: eventsData } = useEvents(data)

  const slots = getSlots(data ?? [])

  // const disabledHomeDeliveries = removeDuplicatedHomeDeliveries(
  //   getUniqueDisabledHomeDeliveries(slots)
  // )

  const disabledBuyouts = getUniqueDisabledEvents(slots, 'BUYOUT')

  const filteredEvents = eventsData
    .sort((a, b) => {
      return getSortingTypeNumber(b.type) - getSortingTypeNumber(a.type)
    })
    .filter(event => {
      return (
        (isOpportunity(event.type) &&
          event.state &&
          isOpportunityShown(event.state)) ||
        (isOrder(event.type) && driverama.flags.IS_HANDOVER_ENABLED_APPS_31) ||
        isReclamation(event.type)
      )
    })

  // const disabledBookingHomeDeliveriesEvents: CustomRBEvent[] = disabledHomeDeliveries.map(
  //   disabledHomeDelivery => ({
  //     id: disabledHomeDelivery.id,
  //     type: 'DISABLED_HOME_DELIVERY' as const,
  //     start: parse(disabledHomeDelivery.from, 'yyyy-MM-dd', new Date()),
  //     end: addHours(
  //       parse(disabledHomeDelivery.to, 'yyyy-MM-dd', new Date()),
  //       24
  //     ),
  //     title: <DisabledDeliveryTitle />,
  //     allDay: true
  //   })
  // )

  const disabledBookingBuyingEvents: CustomRBEvent[] = disabledBuyouts.map(
    disabledBuyout => ({
      id: disabledBuyout.id,
      type: 'DISABLED_BUYOUT' as const,
      start: parse(disabledBuyout.from, 'yyyy-MM-dd', new Date()),
      end: addHours(parse(disabledBuyout.to, 'yyyy-MM-dd', new Date()), 24),
      title: <DisabledBuyoutTitle />,
      allDay: true
    })
  )

  return {
    events: {
      isLoading,
      isError,
      data: [...filteredEvents, ...disabledBookingBuyingEvents]
    },
    slots
  }
}

function DisabledBuyoutTitle() {
  const { t } = useTranslation('core')

  return (
    <Flex variant="row" align="center" gap={1}>
      <IconMoney width={20} height={20} />
      <TextBody css={{ fontSize: 13 }}>{t('buyout_disabled')}</TextBody>
    </Flex>
  )
}

// function DisabledDeliveryTitle() {
//   const { t } = useTranslation('core')

//   return (
//     <Flex variant="row" align="center" gap={1}>
//       <IconHome width={20} height={20} />
//       <TextBody css={{ fontSize: 13 }}>{t('delivery_disabled')}</TextBody>
//     </Flex>
//   )
// }

export const getBookingFilterDates = (
  activeDailyReportFilter: DailyReportKey | undefined
) => {
  // return null to have full range
  if (
    !activeDailyReportFilter ||
    activeDailyReportFilter === 'priceGuaranteeTotal'
  ) {
    return null
  }

  if (activeDailyReportFilter === 'appointmentsTomorrow') {
    return format(addDays(new Date(), 1), 'yyyy-MM-dd')
  }

  return format(new Date(), 'yyyy-MM-dd')
}
